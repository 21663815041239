<template>
  <div id="passportContainer">
    <!-- <PowerHead />
    <router-view></router-view> -->
  </div>
</template>
<script>
// import PowerHead from "@components/layout/PowerHead";
import { mapMutations } from "vuex";

export default {
  name: "PassPort",
  // components: { PowerHead }
  created() {
    let query = this.$route.query;
    let token = query["auth-token"];
    if (token) {
      let xppLoginMsg = localStorage.getItem("xppLoginMsg");
      if (xppLoginMsg) {
        this.$message(xppLoginMsg);
        localStorage.removeItem("xppLoginMsg");
      }
      this.getUserInfo(token).then(() => {
        let returnUrl = sessionStorage.getItem("returnUrl");
        if (returnUrl) {
          sessionStorage.removeItem("returnUrl");
          location.href = returnUrl;
        } else {
          location.href = "/project";
        }
      });
    } else {
      location.href = `https://dev.x-canvas.net/dis/auth/login`;
      // this.$axios
      //   .get("/dis/auth/login", {
      //     params: {
      //       url: encodeURIComponent(location.href)
      //     }
      //   })
      //   .then(res => {
      //     // location.href = res.loginUrl;
      //   });
    }
  },
  methods: {
    async getUserInfo(token) {
      localStorage.setItem("token", token);
      let { data } = await this.$axios.get("/dis/auth/info", {
        headers: {
          "AUTH-TOKEN": token
        }
      });
      if (!data.headImg) {
        data.headImg = "/img/profile_default.png";
      }
      data.roleauth = "user";
      this.$SaveXppUserInfo(data);
    },
    ...mapMutations(["$SaveXppUserInfo"])
  }
};
</script>
<style lang="scss" scoped>
#passportContainer {
  height: 100vh;
}
</style>
